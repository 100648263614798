import { forwardRef, useCallback, useContext, useState } from "react";
import { InView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";
import * as animationData from "../assets/animations/celebrate.json";
import PolestarZeeburgereiland from "../assets/photos/Polestar/polestar-zeeburgereiland.png";
// import Polestar from "../assets/photos/cars/polestar-krijgsman.webp";
import Polestar from "../assets/heroslider/polestar.png";
import AudiHeaderImage from "../assets/photos/Audi/audi-header.png";
import Volkswagen from "../assets/photos/cars/volkswagen-krijgsman.png";

import { Button } from "../components/Button";
import DealLabel from "../components/DealLabel";
import HeroUsp from "../components/Hero/HeroUsp";
import useWindowDimensions from "../hooks/useWindowDimensions";

import "swiper/css";
import { HeroComparison } from "../components/Hero/HeroComparison";

export const HeroBlock = forwardRef(
  (
    {
      setShowVideoPlayer,
      showAppDownloadModal,
      city,
      showSwirl = false,
      setShowAppModal,
      setCurrentlyInView,
    },
    ref
  ) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const [currentSlide, setCurrentSlide] = useState(0);
    const { selectedCity } = useContext(UserContext);
    const location = useLocation();
    const { width } = useWindowDimensions();

    const renderImage = () => {
      switch (selectedCity.name) {
        case "De Krijgsman":
          return Polestar;
        case "Zeeburgereiland":
          return PolestarZeeburgereiland;
        default:
          return Volkswagen;
      }
    };

    const [swiperRef, setSwiperRef] = useState();

    const handlePrevious = useCallback(() => {
      swiperRef?.slidePrev();
    }, [swiperRef]);

    const handleNext = useCallback(() => {
      swiperRef?.slideNext();
    }, [swiperRef]);

    return (
      <InView
        ref={ref}
        className="relative mt-10 flex w-full flex-col items-center  py-[60px] px-5 md:py-[80px] md:px-10 lg:py-[140px] "
        as="div"
        threshold={width < 744 ? 0.3 : 0.4}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Home")
        }
      >
        <div className="flex w-full flex-col  justify-center space-y-[50px] self-center  md:max-w-[744px] lg:w-full lg:max-w-[1150px] lg:flex-row lg:space-y-0 lg:space-x-[100px] ">
          <div className="flex flex-col lg:w-[500px]">
            <div className="mb-[10px] self-center lg:self-start">
              <DealLabel />
            </div>

            <div className="mb-[50px] px-[10.5px] lg:px-0">
              <h1 className="mb-5 px-[13.5px] text-center text-[36px] leading-[40px] tracking-[-0.5px] lg:px-0 lg:text-start lg:text-[56px] lg:leading-[64px]">
                Luxe deelauto's{" "}
                {selectedCity.name === "Alle"
                  ? "voor scherpe tarieven"
                  : "in " + selectedCity.name}
              </h1>

              <p className="mb-5 text-center lg:text-start">
                Bij Vloto rijd je in een 100% elektrische, luxe én goed
                onderhouden auto voor{" "}
                <b className="text-black">lage tarieven</b>! Kies uit de Audi
                e-tron, Polestar 2, Mercedes EQB of Volkswagen ID4.
              </p>

              <div className="flex flex-col space-y-[12px] px-[14.5px] lg:px-0">
                <HeroUsp text="Reclamevrije, luxe auto’s met lage tarieven" />
                <HeroUsp text="Schoon, stil en duurzaam vervoer" />
                <HeroUsp text="Altijd een auto op loopafstand" />
              </div>
            </div>

            <div className="mb-[50px] flex flex-col space-y-3 px-[45.5px] md:flex-row md:items-center md:justify-center md:space-y-0 md:space-x-4 md:px-[125px] lg:items-start lg:justify-start lg:px-0">
              <div className="">
                <Button
                  title={"Download de app"}
                  variant="solid"
                  onClick={showAppDownloadModal}
                />
              </div>

              <Button
                title={"Video afspelen"}
                variant="playVideo"
                onClick={() => setShowVideoPlayer(true)}
              />
            </div>
          </div>
          {selectedCity.id === 12 ||
          selectedCity.id === 13 ||
          selectedCity.id === 14 ? (
            <div className="flex items-center justify-center">
              <img src={AudiHeaderImage} alt="Audi_header" />
            </div>
          ) : (
            <HeroComparison />
          )}
        </div>
      </InView>
    );
  }
);
