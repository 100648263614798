import React, { useEffect, useMemo, useRef, useState } from "react";
import { scrollToSection } from "../../pages/Boats/BoatPage";
import { BoatSectionTag } from "./BoatSectionTag";
import { useLocation } from "react-router-dom";

export const CarSectionHeader = ({
  currentlyInView,
  showHeaderSectionBorder,
  homeRef,
  benefitsRef,
  ourCarsRef,
  ourAppRef,
  priceRef,
  reviewRef,
  communitiesRef,
  howItWorksRef,
  headerBackgroundColor,
}) => {
  const location = useLocation();

  const isSignUpPage = location.pathname === "/signup";

  const sections = useMemo(() => {
    return isSignUpPage
      ? [
          {
            id: 1,
            key: "Overview",
            name: "Overview",
            ref: homeRef,
          },
          {
            id: 2,
            key: "Onze auto's",
            name: "Onze auto's",
            ref: ourCarsRef,
          },
          {
            id: 3,
            key: "Tarieven",
            name: "Tarieven",
            ref: priceRef,
          },
          {
            id: 4,
            key: "Voordelen",
            name: "Voordelen",
            ref: benefitsRef,
          },
          {
            id: 5,
            key: "Reviews",
            name: "Reviews",
            ref: reviewRef,
          },
          {
            id: 6,
            key: "Onze app",
            name: "Onze app",
            ref: ourAppRef,
          },
        ]
      : [
          {
            id: 1,
            key: "Home",
            name: "Home",
            ref: homeRef,
          },
          {
            id: 2,
            key: "Voordelen",
            name: "Voordelen",
            ref: benefitsRef,
          },
          {
            id: 3,
            key: "Reviews",
            name: "Reviews",
            ref: reviewRef,
          },
          {
            id: 4,
            key: "Onze auto's",
            name: "Onze auto's",
            ref: ourCarsRef,
          },
          {
            id: 5,
            key: "Tarieven",
            name: "Tarieven",
            ref: priceRef,
          },
          {
            id: 6,
            key: "Onze app",
            name: "Onze app",
            ref: ourAppRef,
          },
        ];
  }, [
    isSignUpPage,
    homeRef,
    ourCarsRef,
    priceRef,
    benefitsRef,
    reviewRef,
    ourAppRef,
  ]);

  const filteredSections =
    location.pathname === "/"
      ? sections
      : sections.filter((section) => section.key !== "Communities");

  const scrollRef = useRef(null);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [scrollTo, setScrollTo] = useState(null);

  useEffect(() => {
    const newIndex = sections.find(
      (section) => section.key === currentlyInView
    );

    if (newIndex) {
      setCurrentIndex(newIndex.id);
    }
  }, [currentlyInView, sections]);

  useEffect(() => {
    if (!scrollTo) {
      if (currentIndex > 4) {
        scrollRef.current.scrollTo({ left: 300, behavior: "smooth" });
      } else if (currentIndex <= 4) {
        scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
      }
    } else {
      if (
        scrollTo === "Tarieven" ||
        scrollTo === "Communities" ||
        scrollTo === "Reviews" ||
        scrollTo === "Hoe werkt het"
      ) {
        scrollRef.current.scrollTo({ left: 300, behavior: "smooth" });
      } else {
        scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
      }
      setTimeout(() => {
        setScrollTo(null);
      }, 500);
    }
  }, [currentlyInView, currentIndex, scrollTo]);

  return (
    <div
      className={`absolute flex h-11  w-full snap-x snap-mandatory snap-center items-center  space-x-2  ${
        headerBackgroundColor ? "bg-white" : "bg-transparent"
      } px-5  ${
        showHeaderSectionBorder && "border-b-[1px] border-card shadow-header"
      }`}
    >
      <div
        ref={scrollRef}
        className="-mx-5 flex snap-x snap-center space-x-2 overflow-x-scroll px-5 scrollbar-hide lg:mx-auto"
      >
        {filteredSections.map((section) => (
          <div key={section.id}>
            <BoatSectionTag
              section={section.name}
              currentlyInView={currentlyInView === section.key}
              onClick={() => {
                setScrollTo(section.key);
                scrollToSection(section.ref);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
