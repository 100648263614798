import React, { useEffect, useState } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import logo from "../../assets/logo/header-logo.svg";
import { DownloadAppModal } from "../../components/DownloadAppModal";
import { Footer } from "../../components/Footer";
import { HeaderButton } from "../../components/HeaderButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const AppPaymentRoundup = () => {
  const location = useLocation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [showAppModal, setShowAppModal] = useState(false);

  const showAppDownloadModal = () => {
    if (!isMobile) {
      setShowAppModal(true);
      return;
    }

    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vloto.vloto";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/vloto/id1622367728";
    }
  };

  return (
    <div className="flex h-screen flex-col justify-between bg-[#FAFAFA]">
      <div className="sticky top-0 z-50 ">
        <nav
          className={"sticky top-0 z-50 border-b-[1px] border-card bg-white"}
        >
          <div className="mx-auto h-[60px] pl-5 pr-6 lg:px-[80px] xl:max-w-[1440px] xl:px-[160px]">
            <div className="flex h-full justify-between ">
              <div className="flex w-full items-center justify-between">
                <div className="flex-shrink-0">
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => navigate("/")}
                  >
                    <img
                      src={logo}
                      alt="Logo"
                      className="h-5 md:h-6 lg:h-6 xl:h-7 "
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <HeaderButton
                  onClick={showAppDownloadModal}
                  variant={"solid"}
                  title={"Download"}
                />
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div className="mx-auto flex flex-col items-center py-[60px] px-[10.5px] md:max-w-[744px] lg:w-[1120px] lg:max-w-[1120px] lg:px-0">
        <h1 className="mb-5 text-center text-[36px] leading-[40px] tracking-[-0.5px] lg:text-[56px] lg:leading-[64px]">
          Betaling
        </h1>

        <p className="text-center">
          Je hebt het betaalproces afgerond. Bekijk de status van de betaling in
          de app. Let op: zorg dat je de laatste versie van de app hebt.
        </p>
      </div>

      {showAppModal && (
        <Popup
          contentStyle={{
            backgroundColor: "transparent",
            border: "none",
            width: width > 1260 ? 1120 : 744,
          }}
          open={showAppModal}
          onClose={() => setShowAppModal(false)}
        >
          <DownloadAppModal setShowAppModal={setShowAppModal} />
        </Popup>
      )}

      <Footer />
    </div>
  );
};

export default AppPaymentRoundup;
