import { forwardRef, useContext, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import { UserContext } from "../App";
import HeroUsp from "../components/Hero/HeroUsp";
import useWindowDimensions from "../hooks/useWindowDimensions";
import axios from "axios";
import { ReactComponent as SuccessIcon } from "../assets/icons/success-icon.svg";

import "swiper/css";

export const SignUpBlock = forwardRef(
  (
    {
      setShowVideoPlayer,
      showAppDownloadModal,
      city,
      showSwirl = false,
      setShowAppModal,
      setCurrentlyInView,
    },
    ref
  ) => {
    const { selectedCity } = useContext(UserContext);
    const { width } = useWindowDimensions();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [postcode, setPostcode] = useState("");

    const [errors, setErrors] = useState({
      name: "",
      email: "",
      postcode: "",
    });

    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = async (e) => {
      e.preventDefault();

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      const newErrors = {
        name: name.trim() === "" ? "Verplicht" : "",
        email:
          email.trim() === ""
            ? "Verplicht"
            : !emailRegex.test(email)
            ? "Ongeldig e-mailadres"
            : "",
        postcode: postcode.trim() === "" ? "Verplicht" : "",
      };

      setErrors(newErrors);

      const noErrors = Object.values(newErrors).every((error) => error === "");

      if (noErrors) {
        try {
          const response = await axios.post(
            "https://api.prod.app.vloto.nl/site/waitlist",
            {
              name,
              email,
              postal_code: postcode,
            }
          );

          console.log("Success:", response.data);

          setName("");
          setEmail("");
          setPostcode("");

          setIsSuccess(true);
        } catch (error) {
          console.error("Error:", error);
          if (error.response) {
            console.error("Backend error:", error.response.data);
            if (error.response.data.errors) {
              const backendErrors = error.response.data.errors;
              const updatedErrors = { ...errors };

              if (backendErrors.email) {
                updatedErrors.email = "Email is al in gebruik.";
              }
              if (backendErrors.postal_code) {
                updatedErrors.postcode = "Ongeldige postcode.";
              }

              setErrors(updatedErrors);
            } else if (error.response.data.message) {
              console.error("General error: ", error.response.data.message);
            } else {
            }
          } else if (error.request) {
            console.error("No response received:", error.request);
          } else {
            console.error("Error setting up the request:", error.message);
          }
        }
      }
    };

    return (
      <>
        <InView
          ref={ref}
          className="relative mt-10 flex w-full flex-col items-center py-[60px] px-5 md:py-[80px] md:px-10 lg:py-[140px]"
          as="div"
          threshold={width < 744 ? 0.3 : 0.4}
          onChange={(inView, entry) =>
            entry.isIntersecting && setCurrentlyInView("Home")
          }
        >
          <div className="flex w-full flex-col space-y-[50px] self-center md:max-w-[744px] lg:w-full lg:max-w-[1150px] lg:flex-row lg:space-y-0 lg:space-x-[100px]">
            <div className="flex flex-col lg:w-[500px]">
              <div className="px-[10.5px] lg:px-0">
                <h1 className="mb-5 px-[13.5px] text-center text-[36px] leading-[40px] tracking-[-0.5px] lg:px-0 lg:text-start lg:text-[56px] lg:leading-[64px]">
                  Binnenkort beschikbaar in jouw buurt: de luxe deelauto's van
                  Vloto
                </h1>

                <p className="mb-5 text-center lg:text-start">
                  Bij Vloto rijd je in een 100% elektrische, luxe én goed
                  onderhouden auto voor{" "}
                  <b className="text-black">lage tarieven</b>! Kies uit de Audi
                  e-tron, Polestar 2, Mercedes EQB of Volkswagen ID4.
                </p>

                <div className="flex flex-col space-y-[12px] px-[14.5px] lg:px-0">
                  <HeroUsp text="Reclamevrije, luxe auto’s met lage tarieven" />
                  <HeroUsp text="Schoon, stil en duurzaam vervoer" />
                  <HeroUsp text="Altijd een auto op loopafstand" />
                </div>
              </div>
            </div>

            <div className="relative flex h-full w-full flex-col overflow-hidden rounded-[16px] bg-[#272F24] p-5 text-white sm:h-[520px] sm:w-[464] md:h-full md:w-[744px] lg:h-[506px] lg:w-[464px] lg:rounded-[30px] lg:p-[32px]">
              {isSuccess ? (
                <div className="flex h-full flex-col items-center justify-center">
                  <SuccessIcon className="mb-6 text-white" aria-hidden="true" />
                  <p className="px-[60px] text-center text-[16px] text-white sm:leading-6 xl:leading-7">
                    Bedankt voor uw aanmelding. U behoort tot de eerste groep
                    Vloto-gebruikers in uw community.
                  </p>
                </div>
              ) : (
                <>
                  <h2 className="pb-8 text-[24px] font-bold">
                    Schrijf je vast in voor de wachtlijst en krijg extra korting
                  </h2>

                  <form
                    className="flex flex-col space-y-[16px]"
                    onSubmit={handleSubmit}
                  >
                    <div className="flex flex-col space-y-[5px]">
                      <div className="flex justify-between">
                        <label
                          htmlFor="name"
                          className="text-[14px] text-[#AEB4AB]"
                        >
                          Naam
                        </label>
                        {errors.name && (
                          <span className="text-[14px] text-[#EA8686]">
                            {errors.name}
                          </span>
                        )}
                      </div>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (errors.name && e.target.value.trim() !== "") {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              name: "",
                            }));
                          }
                        }}
                        className={`w-full rounded-lg border ${
                          errors.name ? "border-[#EA8686]" : "border-[#AEB4AB]"
                        } bg-[#272F24] p-[10px] text-[16px] focus:border-white focus:outline-none`}
                      />
                    </div>

                    <div className="flex flex-col space-y-[5px]">
                      <div className="flex justify-between">
                        <label
                          htmlFor="email"
                          className="text-[14px] text-[#AEB4AB]"
                        >
                          E-mailadres
                        </label>
                        {errors.email && (
                          <span className="text-[14px] text-[#EA8686]">
                            {errors.email}
                          </span>
                        )}
                      </div>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          if (errors.email && e.target.value.trim() !== "") {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              email: "",
                            }));
                          }
                        }}
                        className={`w-full rounded-lg border ${
                          errors.email ? "border-[#EA8686]" : "border-[#AEB4AB]"
                        } bg-[#272F24] p-[10px] text-[16px] focus:border-white focus:outline-none`}
                      />
                    </div>

                    <div className="flex flex-col space-y-[5px]">
                      <div className="flex justify-between">
                        <label
                          htmlFor="postcode"
                          className="text-[14px] text-[#AEB4AB]"
                        >
                          Postcode
                        </label>
                        {errors.postcode && (
                          <span className="text-[14px] text-[#EA8686]">
                            {errors.postcode}
                          </span>
                        )}
                      </div>
                      <input
                        type="text"
                        id="postcode"
                        name="postcode"
                        value={postcode}
                        onChange={(e) => {
                          setPostcode(e.target.value);
                          if (errors.postcode && e.target.value.trim() !== "") {
                            setErrors((prevErrors) => ({
                              ...prevErrors,
                              postcode: "",
                            }));
                          }
                        }}
                        className={`w-full rounded-lg border ${
                          errors.postcode
                            ? "border-[#EA8686]"
                            : "border-[#AEB4AB]"
                        } bg-[#272F24] p-[10px] text-[16px] focus:border-white focus:outline-none`}
                      />
                    </div>

                    <button
                      type="submit"
                      className="mt-4 rounded-[5px] bg-[#4CAF50] p-[10px] text-[16px] text-white"
                      style={{ marginTop: "38px" }}
                    >
                      Schrijf me in
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </InView>
      </>
    );
  }
);
