import React from "react";

export const PromotionSubscriptionCard = ({
  name,
  pricePerMonth,
  pricePerKm,
  pricePerHour,
  newPricePerMonth,
  newPricePerKm,
  newPricePerHour,
  freeHours,
  recommended = false,
}) => {
  return (
    <div
      className={`relative flex w-full flex-col justify-start overflow-hidden rounded-[16px] outline-[1px] outline-[#AEB4AB] ${
        recommended ? "bg-[#1CA355]" : "bg-[#5D6957]"
      } pl-6 pt-6 pb-[54px]`}
    >
      <p
        className={`${
          recommended ? "text-white" : "text-[#7BE2A6]"
        } mb-2 font-DMSans text-[14px] leading-[22px]`}
      >
        {name}
      </p>

      <div className="mb-[10px] flex flex-col items-start">
        <div className="flex flex-row items-end">
          <h3 className="mr-1 font-DMSans text-[32px] leading-[54px] text-[#AEB4AB] line-through">
            €{pricePerMonth}
          </h3>
          <h3 className="mr-1 font-DMSans text-[32px] leading-[54px] text-white">
            €{newPricePerMonth}
          </h3>
        </div>
        <p className="mt-[-8px] pb-[10px] text-[16px] text-[#E8E8E8]">
          per maand
        </p>
      </div>

      <p className="mb-4 font-DMSans text-[20px] font-bold leading-[28px] text-white lg:w-[198px]">
        {freeHours
          ? `Elke maand eerste ${freeHours} uur gratis`
          : "Geen maandelijkse kosten"}
      </p>

      <ul className="max-w-[176px] list-disc space-y-[2px] pl-5">
        <li className="text-white">
          <span className="text-[#AEB4AB] line-through">€{pricePerHour}</span> €
          {newPricePerHour} per uur na vrije uren
        </li>

        <li className="text-white">
          <span className="text-[#AEB4AB] line-through">€{pricePerKm}</span> €
          {newPricePerKm} per km
        </li>
      </ul>

      {recommended && (
        <div className="absolute -top-[1px] right-0 rounded-bl-[20px] bg-[#E35D5D] px-5 py-[5px] ">
          <p className="font-DMSans text-[14px] leading-[22px] text-white">
            MEEST GEKOZEN
          </p>
        </div>
      )}
    </div>
  );
};
