import { forwardRef, useContext } from "react";
import { InView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";
import * as animationData from "../assets/animations/celebrate.json";
import PolestarZeeburgereiland from "../assets/photos/Polestar/polestar-zeeburgereiland.png";
import Polestar from "../assets/photos/cars/polestar-krijgsman.webp";
import Volkswagen from "../assets/photos/cars/volkswagen-krijgsman.png";
import UspMobile from "../assets/temp/UspMobile.png";
import UspDesktop from "../assets/temp/UspDesktop.png";
import Ellipse from "../assets/temp/ellipse.png";

import DealLabel from "../components/DealLabel";
import NewUspTag from "../components/NewUsp/NewUspTag";
import useWindowDimensions from "../hooks/useWindowDimensions";

export const NewUspBlock = forwardRef(
  (
    {
      setShowVideoPlayer,
      showAppDownloadModal,
      city,
      showSwirl = false,
      setShowAppModal,
      setCurrentlyInView,
      isSignUp = false,
    },
    ref
  ) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    const { selectedCity } = useContext(UserContext);
    const location = useLocation();
    const { width } = useWindowDimensions();

    const renderImage = () => {
      switch (selectedCity.name) {
        case "De Krijgsman":
          return Polestar;
        case "Zeeburgereiland":
          return PolestarZeeburgereiland;
        default:
          return Volkswagen;
      }
    };

    return (
      <InView
        ref={ref}
        className="mt-10 flex w-full flex-col items-center py-[60px] px-5  md:px-10 md:py-[80px] lg:w-[1120px] lg:py-[140px] lg:px-0"
        as="div"
        threshold={width < 744 ? 0.3 : 0.4}
        onChange={(inView, entry) =>
          entry.isIntersecting && setCurrentlyInView("Voordelen")
        }
      >
        <div className="relative flex flex-col overflow-hidden rounded-[16px] bg-[#34432D] px-5  text-start text-white md:max-w-[744px] md:rounded-[30px] lg:w-[1120px] lg:max-w-[1120px] lg:flex-row lg:px-0">
          <img
            src={UspDesktop}
            alt="De app"
            className="hidden h-[614px] w-[481px] lg:flex"
          />
          <img
            src={Ellipse}
            alt="Ellipse"
            className="absolute -bottom-[500px] -right-[500px]"
          />
          <div className="flex flex-col pt-8 lg:w-[570px] lg:pt-[80px]">
            <h5 className="mb-2  text-start lg:mb-[10px]  lg:w-[570px] lg:text-[32px] lg:leading-[40px] ">
              Luxe deelmobiliteit, scherpe tarieven
            </h5>

            <p className="mb-8 text-[#D6D9D5] lg:w-[561px]">
              Stap in de toekomst van mobiliteit met Vloto en kies voor de meest
              luxe deelauto’s bij Vloto. Het huren van een deelauto hoeft geen
              uitzondering te zijn, want bij Vloto geniet je van de voordelen
              van een luxe Audi, Volkswagen, Polestar of Mercedes wanneer dat
              jou uitkomt. 
              <b className="font-bold text-white">
                Volledig geserviced en altijd beschikbaar op loopafstand!
              </b>
            </p>

            <div className="flex flex-col lg:mb-10 lg:flex-row lg:space-x-20">
              <div className="mb-8 space-y-[10px] lg:mb-0 lg:max-w-[220px] lg:space-y-[12px]">
                <p className="font-DMSans text-[16px] font-bold leading-[28px] text-white">
                  Voor wie is Vloto?
                </p>

                <NewUspTag text="Je hebt een flexibele (tweede) auto nodig" />
                <NewUspTag text="Je wilt een luxe rijervaring zonder grote investering" />
                <NewUspTag text="Je zoekt comfortabel vervoer wanneer dat jou uitkomt" />
              </div>

              <div className="mb-8 space-y-[10px] lg:mb-0 lg:max-w-[220px] lg:space-y-[12px]">
                <p className="font-DMSans text-[16px] font-bold leading-[28px] text-white">
                  Waarom is Vloto anders?
                </p>
                <NewUspTag text="Goedkoper dan een tweede auto" />
                <NewUspTag text="Vloto biedt luxe auto’s zonder reclame" />
                <NewUspTag text="Geheel flexibel & altijd op loopafstand beschikbaar" />
              </div>
            </div>

            <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-5">
              {!isSignUp ? (
                <>
                  <button
                    onClick={showAppDownloadModal}
                    className="z-20 h-[48px] w-[221px] cursor-pointer rounded-[8px] bg-lightGreen font-semibold leading-[18px] text-[#2A3624]"
                  >
                    Download de app
                  </button>
                  <DealLabel />
                </>
              ) : (
                <button
                  onClick={() => {
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                  variant="solid"
                  className="z-20 h-[48px] w-[221px] cursor-pointer rounded-[8px] bg-lightGreen font-semibold leading-[18px] text-[#2A3624]"
                >
                  Schrijf me in
                </button>
              )}
            </div>

            <img
              src={UspMobile}
              alt="Afbeelding van de app"
              className="self-center lg:hidden"
            />
          </div>
        </div>
      </InView>
    );
  }
);
