import { useContext, useEffect, useRef, useState } from "react";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import ReactPlayer from "react-player/lazy";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AppUseBlock } from "../../blocks/AppUseBlock";
import { CarBlock } from "../../blocks/CarBlock";
import { FaqBlock } from "../../blocks/FaqBlock";
import { HeroBlock } from "../../blocks/HeroBlock";
import { SubscriptionCalculationBlock } from "../../blocks/SubscriptionCalculationBlock";
import { USPBlock } from "../../blocks/USPBlock";
import { DownloadAppModal } from "../../components/DownloadAppModal";

import ScrollToTop from "../../components/ScrollToTop";
import useWindowDimensions from "../../hooks/useWindowDimensions";

import { UserContext } from "../../App";
import { NewUspBlock } from "../../blocks/NewUspBlock";
import { ReviewBlock } from "../../blocks/ReviewBlock";
import { ExclusiveBanner } from "../../components/Banners/ExclusiveBanner";
import { BoatFooter } from "../../components/BoatFooter";
import ComparisonTable from "../../components/ComparisonTable";
import { Header } from "../../components/NewHeader";
import { RegisterBlock } from "../../blocks/RegisterBlock";
import { SignUpBlock } from "../../blocks/SignUpBlock";

const SignUpPage = ({ city }) => {
  const { selectedCity } = useContext(UserContext);
  const { width } = useWindowDimensions();

  const [locationState, setLocationState] = useState(null);
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  const [currentlyInView, setCurrentlyInView] = useState("Home");
  const [showHeaderSectionBorder, setShowHeaderSectionBorder] = useState(false);

  const TRACKING_ID = "G-8GVPPG2RFP";

  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const showAppDownloadModal = () => {
    if (!isMobile) {
      setShowAppModal(true);
      return;
    }

    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.vloto.vloto";
    } else if (isIOS) {
      window.location.href = "https://apps.apple.com/us/app/vloto/id1622367728";
    }
  };

  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [showAppModal, setShowAppModal] = useState(false);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (scrollPosition > 40) {
      setHeaderBackgroundColor(true);
      setShowHeaderSectionBorder(true);
    } else {
      setHeaderBackgroundColor(false);
      setShowHeaderSectionBorder(false);
    }
  }, [scrollPosition]);

  const homeRef = useRef(null);
  const benefitsRef = useRef(null);
  const ourCarsRef = useRef(null);
  const ourAppRef = useRef(null);
  const reviewRef = useRef(null);
  const priceRef = useRef(null);
  const communitiesRef = useRef(null);
  const howItWorksRef = useRef(null);

  return (
    <div className="flex flex-col bg-[#FAFAFA]">
      <ScrollToTop />

      <Header
        showAppDownloadModal={showAppDownloadModal}
        isHeaderOpen={isHeaderOpen}
        setIsHeaderOpen={setIsHeaderOpen}
        currentlyInView={currentlyInView}
        showHeaderSectionBorder={showHeaderSectionBorder}
        headerBackgroundColor={headerBackgroundColor}
        homeRef={homeRef}
        benefitsRef={benefitsRef}
        ourCarsRef={ourCarsRef}
        ourAppRef={ourAppRef}
        priceRef={priceRef}
        communitiesRef={communitiesRef}
        howItWorksRef={howItWorksRef}
        reviewRef={reviewRef}
      />
      <div
        onTouchStart={() => setIsHeaderOpen(false)}
        onClick={() => setIsHeaderOpen(false)}
        className={`z-40 ${
          isHeaderOpen &&
          "bg-white opacity-10 transition-opacity duration-200 ease-in-out"
        } `}
      >
        <div className="flex flex-col items-center bg-[#FAFAFA]">
          {showVideoPlayer && (
            <Popup
              contentStyle={{
                backgroundColor: "transparent",
                border: "none",
                width: "90%",
              }}
              lockScroll={true}
              open={showVideoPlayer}
              onClose={() => setShowVideoPlayer(false)}
            >
              <div className="h-80 w-full items-center justify-center px-2 md:h-[500px] lg:h-[700px] xl:h-[700px]">
                <ReactPlayer
                  url="https://youtu.be/8vsVJRCq_sM"
                  width={"100%"}
                  height={"100%"}
                  muted={false}
                  volume={1}
                  controls={false}
                  style={{
                    borderRadius: 15,
                    overflow: "hidden",
                  }}
                />
              </div>
            </Popup>
          )}

          {showAppModal && (
            <Popup
              contentStyle={{
                backgroundColor: "transparent",
                border: "none",
                width: width > 1260 ? 1120 : 744,
              }}
              open={showAppModal}
              onClose={() => setShowAppModal(false)}
            >
              <DownloadAppModal setShowAppModal={setShowAppModal} />
            </Popup>
          )}

          <SignUpBlock
            ref={homeRef}
            setCurrentlyInView={setCurrentlyInView}
            city={city}
            showSwirl={selectedCity.name === "De Krijgsman"}
            showAppDownloadModal={showAppDownloadModal}
            showVideoPlayer={showVideoPlayer}
            setShowVideoPlayer={setShowVideoPlayer}
          />

          <CarBlock ref={ourCarsRef} setCurrentlyInView={setCurrentlyInView} />

          <SubscriptionCalculationBlock
            setCurrentlyInView={setCurrentlyInView}
            city={city}
            ref={priceRef}
            showAppDownloadModal={showAppDownloadModal}
            isSignUp={true}
          />

          <USPBlock
            city={selectedCity.name}
            ref={benefitsRef}
            setCurrentlyInView={setCurrentlyInView}
          />

          <NewUspBlock
            showAppDownloadModal={showAppDownloadModal}
            setCurrentlyInView={setCurrentlyInView}
            isSignUp={true}
          />

          <ReviewBlock
            ref={reviewRef}
            setCurrentlyInView={setCurrentlyInView}
          />

          <AppUseBlock
            setCurrentlyInView={setCurrentlyInView}
            showAppDownloadModal={showAppDownloadModal}
            ref={ourAppRef}
            isSignUp={true}
          />

          <FaqBlock />
        </div>

        <BoatFooter setShowAppModal={showAppDownloadModal} city={city} />
      </div>
    </div>
  );
};

export default SignUpPage;
