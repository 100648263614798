import { useNavigate } from "react-router-dom";

export const RegisterBlock = () => {
  const navigate = useNavigate();

  return (
    <div
      className="relative flex max-h-[328px] items-center justify-center overflow-hidden rounded-[16px]
      bg-vlotoGreen md:h-[360px] md:w-[664px] md:rounded-[30px]
      lg:h-[328px] lg:w-[1120px] lg:rounded-[30px]"
    >
      <div
        className="z-20 flex flex-col items-center justify-start space-y-[40px]
        px-5 pt-[60px] pb-[60px] text-white md:px-10 lg:px-16"
      >
        <h5
          className="w-full max-w-[480px] text-center text-xl font-semibold leading-[32px]
          md:max-w-[550px] md:text-2xl md:leading-[36px]
          lg:max-w-[640px] lg:text-[32px] lg:leading-[40px]"
        >
          Is Vloto nog niet in jouw buurt beschikbaar en wil je dat we Vloto
          naar jouw buurt brengen? Meld je dan aan.
        </h5>
        <button
          onClick={() => navigate("/signup")}
          className="w-[200px] rounded-[8px] bg-white py-[15px] px-[34.5px]
          font-semibold leading-[18px] text-vlotoGreen"
        >
          Schrijf me in
        </button>
      </div>
    </div>
  );
};
